import React, { useState } from 'react';
import RatingSelector from './RatingSelector';

function VisitedModal({ place, onClose, onSave }) {
  const [rating, setRating] = useState(null);
  const [notes, setNotes] = useState('');

  const handleSave = () => {
    onSave(rating, notes);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content visited-modal-content">
        <h2 style={{ fontSize: '18px' }}>Mark as visited: {place.name}</h2>
        <div className="visited-modal-rating" style={{width:'90%', marginTop:'10px'}}>
          <p>Select your rating (optional):</p>
          <RatingSelector rating={rating} setRating={setRating} />
        </div>
        <div className="visited-modal-notes" style={{width:'90%', marginTop:'20px'}}>
          <label style={{display:'block', marginBottom:'5px'}}>Visit Notes (optional):</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="What dishes were good?"
            style={{ fontFamily: 'inherit', fontSize: '14px', width:'100%', height:'80px' }}
          />
        </div>
        <div className="modal-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default VisitedModal;
