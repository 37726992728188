import React, { useEffect, useRef, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../config';
import { FaMapPin } from 'react-icons/fa';

function PlaceModal({ isEdit, place, onClose, onSave }) {
  const [query, setQuery] = useState(place ? place.name : '');
  const [predictions, setPredictions] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(() => {
    if (place) {
      return {
        name: place.name,
        stars: place.stars || '',
        website: place.website || '',
        location: place.location || null,
        photoURL: place.photoURL || '',
        visited: place.visited || false,
        visitedRating: place.visitedRating || null,
        visitedNotes: place.visitedNotes || '',
        category: place.category || null, 
      };
    } else {
      return {
        visited:false,
        visitedRating:null,
        visitedNotes:'',
        category:null
      };
    }
  });

  const [remarks, setRemarks] = useState(place ? (place.remarks || '') : '');
  const [category, setCategory] = useState(selectedPlace.category || null);

  const [editVisitedRating, setEditVisitedRating] = useState(selectedPlace.visitedRating);
  const [editVisitedNotes, setEditVisitedNotes] = useState(selectedPlace.visitedNotes);

  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    if (!window.google) return;
    if (!autocompleteService.current) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!placesService.current) {
      const dummyMap = document.createElement('div');
      placesService.current = new window.google.maps.places.PlacesService(dummyMap);
    }
  }, []);

  const handleChange = (e) => {
    setQuery(e.target.value);
    if (autocompleteService.current && e.target.value.trim().length > 0) {
      autocompleteService.current.getPlacePredictions({
        input: e.target.value, 
        location: new window.google.maps.LatLng(1.3521, 103.8198),
        radius: 20000, 
        types: ['establishment']
      }, (preds) => {
        if (preds) {
          setPredictions(preds);
        } else {
          setPredictions([]);
        }
      });
    } else {
      setPredictions([]);
    }
  };

  const handleSelectPlace = (prediction) => {
    placesService.current.getDetails({ placeId: prediction.place_id }, (newPlace, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const name = newPlace.name || '';
        const rating = newPlace.rating || '';
        const website = newPlace.website || '';
        const location = newPlace.geometry && newPlace.geometry.location
          ? { lat: newPlace.geometry.location.lat(), lng: newPlace.geometry.location.lng() }
          : null;

        let photoURL = null;
        if (newPlace.photos && newPlace.photos.length > 0) {
          photoURL = newPlace.photos[0].getUrl({ maxWidth: 400, maxHeight: 400 });
        }

        setSelectedPlace(prev => ({
          ...prev,
          name,
          stars: rating,
          website,
          location,
          photoURL
        }));
        setQuery(name);
        setPredictions([]);
      }
    });
  };

  const handleSave = async () => {
    if (selectedPlace && selectedPlace.location) {
      const updatedData = {
        name: selectedPlace.name,
        stars: selectedPlace.stars,
        website: selectedPlace.website,
        location: selectedPlace.location,
        photoURL: selectedPlace.photoURL,
        remarks,
        category: category 
      };

      if (selectedPlace.visited) {
        updatedData.visited = true;
        updatedData.visitedRating = editVisitedRating;
        updatedData.visitedNotes = editVisitedNotes;
      } else {
        updatedData.visited = false;
        updatedData.visitedRating = null;
        updatedData.visitedNotes = '';
      }

      await onSave(updatedData);
    }
  };

  const title = isEdit ? "Edit place" : "Add a new place";

  // Category button style changes
  const mustGoColor = '#8B0000';   // dark red
  const luxuryColor = '#b8860b';   // dark yellow
  const healthyColor = '#006400';  // dark green

  const getPinStyle = (cat) => {
    const selected = category === cat;
    const baseStyle = { 
      background: selected ? '#ccc' : '#eee',
      border:'none',
      padding:'5px 10px',
      borderRadius:'4px',
      cursor:'pointer',
      fontSize:'14px',
      display:'inline-flex',
      alignItems:'center',
      gap:'5px'
    };
    let textColor = 'blue';
    if (cat==='mustgo') textColor = mustGoColor;
    if (cat==='luxury') textColor = luxuryColor;
    if (cat==='healthy') textColor = healthyColor;
    baseStyle.color = textColor;
    return baseStyle;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 style={{ fontSize: '18px' }}>{title}</h2>
        <div className="modal-search-container">
          <input
            type="text"
            value={query}
            onChange={handleChange}
            placeholder="Search for a place..."
            className="autocomplete-input"
          />
        </div>
        {predictions.length > 0 && (
          <ul className="predictions-list">
            {predictions.map((p) => {
              const mainText = p.structured_formatting.main_text;
              const secondaryText = p.structured_formatting.secondary_text;
              const displayText = secondaryText ? `${mainText}, ${secondaryText}` : mainText;
              return (
                <li key={p.place_id} onClick={() => handleSelectPlace(p)}>
                  {displayText}
                </li>
              );
            })}
          </ul>
        )}

        {selectedPlace && (
          <div className="place-details">
            <p><strong>Name:</strong> {selectedPlace.name}</p>
            <p><strong>Stars (on Google):</strong> {selectedPlace.stars}</p>
            <p><strong>Website:</strong> {selectedPlace.website ? <a href={selectedPlace.website} target="_blank" rel="noreferrer">{selectedPlace.website}</a> : 'N/A'}</p>
            <div>
              <label><strong>Remarks:</strong></label>
              <textarea 
                value={remarks} 
                onChange={(e) => setRemarks(e.target.value)} 
                placeholder="E.g. famous for their spaghetti..."
                style={{ fontFamily: 'inherit', fontSize: '14px' }}
              />
            </div>

            <div className="category-options" style={{marginTop:'10px'}}>
              <p>Select a category (or none for others):</p>
              <div className="category-buttons">
                <button 
                  style={getPinStyle('mustgo')}
                  title="Must go: Red pin"
                  onClick={() => setCategory(category==='mustgo'?null:'mustgo')}
                >
                  <FaMapPin /> Must go
                </button>
                <button 
                  style={getPinStyle('luxury')}
                  title="Luxury: Gold pin"
                  onClick={() => setCategory(category==='luxury'?null:'luxury')}
                >
                  <FaMapPin /> Luxury
                </button>
                <button 
                  style={getPinStyle('healthy')}
                  title="Healthy: Green pin"
                  onClick={() => setCategory(category==='healthy'?null:'healthy')}
                >
                  <FaMapPin /> Healthy
                </button>
              </div>
              <p style={{fontSize:'12px',color:'#555'}}>If none selected, it will be Others (blue pin).</p>
            </div>

            {selectedPlace.visited && (
              <div className="visited-edit-fields" style={{ marginTop:'20px', width:'100%' }}>
                <h3 style={{fontSize:'16px', margin:'0 0 10px 0'}}>Edit Visited Info</h3>
                <div style={{marginBottom:'10px'}}>
                  <label style={{display:'block', marginBottom:'5px'}}>Visited Rating (optional):</label>
                  <div className="rating-options">
                    {[1,2,3,4,5].map(r => (
                      <button
                        key={r}
                        className={editVisitedRating === r ? 'rating-btn selected' : 'rating-btn'}
                        onClick={() => setEditVisitedRating(r)}
                      >
                        {r} ★
                      </button>
                    ))}
                  </div>
                </div>
                <div style={{marginTop:'10px'}}>
                  <label style={{display:'block', marginBottom:'5px'}}>Visit Notes (optional):</label>
                  <textarea
                    value={editVisitedNotes}
                    onChange={(e) => setEditVisitedNotes(e.target.value)}
                    placeholder="Your visit notes..."
                    style={{width:'100%', height:'80px', fontFamily:'inherit', fontSize:'14px', boxSizing:'border-box'}}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="modal-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave} disabled={!selectedPlace || !selectedPlace.location}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default PlaceModal;
