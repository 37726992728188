import React from 'react';

function RatingSelector({ rating, setRating }) {
  return (
    <div className="rating-options">
      {[1,2,3,4,5].map(r => (
        <button
          key={r}
          className={rating === r ? 'rating-btn selected' : 'rating-btn'}
          onClick={() => setRating(r)}
        >
          {r} ★
        </button>
      ))}
    </div>
  );
}

export default RatingSelector;
