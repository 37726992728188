import React, { useState, useEffect } from 'react';
import './styles.css';
import PlaceList from './components/PlaceList';
import MapComponent from './components/MapComponent';
import PlaceModal from './components/PlaceModal';
import VisitedModal from './components/VisitedModal'; 
import { db } from './firebase';
import { collection, doc, onSnapshot, deleteDoc, updateDoc, addDoc, getDoc, setDoc } from 'firebase/firestore';
import { useIsMobile } from './useIsMobile';
import { IoArrowBackOutline } from 'react-icons/io5';

function App() {
  const [places, setPlaces] = useState([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editingPlace, setEditingPlace] = useState(null);

  const [showVisitedModal, setShowVisitedModal] = useState(false);
  const [visitedPlace, setVisitedPlace] = useState(null); 

  const isMobile = useIsMobile();

  const [viewMode, setViewMode] = useState('list'); 
  const [focusedPlaceId, setFocusedPlaceId] = useState(null); 
  const [focusedOrigin, setFocusedOrigin] = useState('list');

  // Toggles and expansions states
  const [showMustGo, setShowMustGo] = useState(true);
  const [showLuxury, setShowLuxury] = useState(true);
  const [showHealthy, setShowHealthy] = useState(true);
  const [showOthers, setShowOthers] = useState(true);
  const [showVisited, setShowVisited] = useState(false);

  const [mustGoExpanded, setMustGoExpanded] = useState(true);
  const [luxuryExpanded, setLuxuryExpanded] = useState(true);
  const [healthyExpanded, setHealthyExpanded] = useState(true);
  const [othersExpanded, setOthersExpanded] = useState(true);
  const [visitedExpanded, setVisitedExpanded] = useState(true);

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  useEffect(() => {
    document.title = "SG Food List";
    const unsubPlaces = onSnapshot(collection(db, 'places'), (snapshot) => {
      const loadedPlaces = snapshot.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data()
      }));
      setPlaces(loadedPlaces);
    });

    // Load settings from Firestore
    const loadSettings = async () => {
      const settingsDoc = doc(db, 'settings', 'userSettings');
      const snap = await getDoc(settingsDoc);
      if (snap.exists()) {
        const data = snap.data();
        if (data.showMustGo !== undefined) setShowMustGo(data.showMustGo);
        if (data.showLuxury !== undefined) setShowLuxury(data.showLuxury);
        if (data.showHealthy !== undefined) setShowHealthy(data.showHealthy);
        if (data.showOthers !== undefined) setShowOthers(data.showOthers);
        if (data.showVisited !== undefined) setShowVisited(data.showVisited);

        if (data.mustGoExpanded !== undefined) setMustGoExpanded(data.mustGoExpanded);
        if (data.luxuryExpanded !== undefined) setLuxuryExpanded(data.luxuryExpanded);
        if (data.healthyExpanded !== undefined) setHealthyExpanded(data.healthyExpanded);
        if (data.othersExpanded !== undefined) setOthersExpanded(data.othersExpanded);
        if (data.visitedExpanded !== undefined) setVisitedExpanded(data.visitedExpanded);
      } else {
        // No doc, use defaults and create doc
        await setDoc(settingsDoc, {
          showMustGo,
          showLuxury,
          showHealthy,
          showOthers,
          showVisited,
          mustGoExpanded,
          luxuryExpanded,
          healthyExpanded,
          othersExpanded,
          visitedExpanded
        });
      }
      setSettingsLoaded(true);
    };
    loadSettings();

    return () => {
      unsubPlaces();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save settings to Firestore whenever they change (after initial load)
  const saveSettings = async () => {
    if (!settingsLoaded) return;
    const settingsDoc = doc(db, 'settings', 'userSettings');
    await setDoc(settingsDoc, {
      showMustGo,
      showLuxury,
      showHealthy,
      showOthers,
      showVisited,
      mustGoExpanded,
      luxuryExpanded,
      healthyExpanded,
      othersExpanded,
      visitedExpanded
    }, {merge:true});
  };

  useEffect(() => {
    if (settingsLoaded) {
      saveSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMustGo, showLuxury, showHealthy, showOthers, showVisited, mustGoExpanded, luxuryExpanded, healthyExpanded, othersExpanded, visitedExpanded]);

  useEffect(() => {
    if (focusedPlaceId) {
      const focusedPlace = places.find(p => p.id === focusedPlaceId);
      if (!focusedPlace) {
        setFocusedPlaceId(null);
        setViewMode(focusedOrigin);
        setEditingPlace(null);
        setShowModal(false);
        setShowVisitedModal(false);
      }
    }
  }, [places, focusedPlaceId, focusedOrigin]);

  useEffect(() => {
    window.history.pushState({}, '');
    const popHandler = () => {
      if (focusedPlaceId) {
        handleBack();
      } else if (isMobile && viewMode === 'map') {
        setViewMode('list');
      }
    };
    window.onpopstate = popHandler;
    return () => {
      window.onpopstate = null;
    };
  }, [focusedPlaceId, isMobile, viewMode]);

  // Escape key to close modals on desktop
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        if (!isMobile) {
          if (showModal || editingPlace || showVisitedModal) {
            if (showModal || editingPlace) {
              setShowModal(false);
              setEditingPlace(null);
            }
            if (showVisitedModal) {
              setShowVisitedModal(false);
              setVisitedPlace(null);
            }
          }
        }
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [showModal, editingPlace, showVisitedModal, isMobile]);

  const addNewPlace = async (newPlace) => {
    await addDoc(collection(db, 'places'), {
      ...newPlace,
      visited: false,
      visitedRating: null,
      visitedNotes: '',
      category: newPlace.category || null
    });
  };

  const updatePlace = async (id, updatedData) => {
    await updateDoc(doc(db, 'places', id), updatedData);
  };

  const handleMarkerClick = (id) => {
    if (isMobile) {
      if (focusedPlaceId === id) {
        setFocusedPlaceId(null);
      } else {
        setFocusedPlaceId(id);
        setFocusedOrigin('map');
      }
    } else {
      if (selectedPlaceId === id) {
        setSelectedPlaceId(null);
      } else {
        setSelectedPlaceId(id);
      }
    }
  };

  const handlePlaceClick = (id) => {
    if (isMobile) {
      if (focusedPlaceId === id) {
        setFocusedPlaceId(null);
      } else {
        setFocusedPlaceId(id);
        setFocusedOrigin('list');
      }
    } else {
      if (selectedPlaceId === id) {
        setSelectedPlaceId(null);
      } else {
        setSelectedPlaceId(id);
      }
    }
  };

  const handleDeletePlace = async (id) => {
    await deleteDoc(doc(db, 'places', id));
    if (selectedPlaceId === id) setSelectedPlaceId(null);
    if (focusedPlaceId === id) {
      setFocusedPlaceId(null);
      setViewMode(focusedOrigin);
      setEditingPlace(null);
      setShowModal(false);
      setShowVisitedModal(false);
    }
  };

  const handleEditPlace = (id) => {
    const place = places.find(p => p.id === id);
    if (place) {
      setEditingPlace(place);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingPlace(null);
  };

  const handleSave = async (data) => {
    if (editingPlace) {
      await updatePlace(editingPlace.id, data);
      setEditingPlace(null);
    } else {
      await addNewPlace(data);
    }
    handleCloseModal();
  };

  const handleBack = () => {
    setFocusedPlaceId(null);
    setViewMode(focusedOrigin);
    setEditingPlace(null);
    setShowModal(false);
    setShowVisitedModal(false);
  };

  const handleAddPlace = () => setShowModal(true);

  const handleVisitedIconClick = async (place) => {
    if (place.visited) {
      await updatePlace(place.id, {
        visited: false,
        visitedRating: null,
        visitedNotes: ''
      });
    } else {
      setVisitedPlace(place);
      setShowVisitedModal(true);
    }
  };

  const handleCloseVisitedModal = () => {
    setShowVisitedModal(false);
    setVisitedPlace(null);
  };

  const handleVisitedSave = async (rating, notes) => {
    await updatePlace(visitedPlace.id, {
      visited: true,
      visitedRating: rating,
      visitedNotes: notes
    });
    handleCloseVisitedModal();
  };

  const focusedPlace = focusedPlaceId ? places.find(p => p.id === focusedPlaceId) : null;
  const showFocusedCard = focusedPlaceId && focusedPlace && !showModal && !editingPlace && !showVisitedModal;

  // If settings not loaded yet, show loading to ensure we apply settings first
  if (!settingsLoaded) {
    return <div style={{textAlign:'center', padding:'20px'}}>Loading Settings...</div>;
  }

  // Filter places for MapComponent with loaded toggles
  let filteredPlacesForMap = places.filter(p => {
    if (p.visited && !showVisited) return false;
    if (!p.visited) {
      if (p.category==='mustgo' && !showMustGo) return false;
      if (p.category==='luxury' && !showLuxury) return false;
      if (p.category==='healthy' && !showHealthy) return false;
      if ((p.category===null||p.category===undefined) && !showOthers) return false;
    }
    return true;
  });

  // If a place is selected but not shown due to toggles, show it anyway in purple icon
  if (selectedPlaceId) {
    const selectedPlace = places.find(p => p.id === selectedPlaceId);
    if (selectedPlace && !filteredPlacesForMap.includes(selectedPlace)) {
      filteredPlacesForMap = [...filteredPlacesForMap, selectedPlace];
    }
  }

  if (!isMobile) {
    return (
      <div className="app-container" style={{paddingTop:'5px'}}> 
        <div className="map-container-fullscreen">
          <MapComponent 
            places={filteredPlacesForMap} 
            onMarkerClick={handleMarkerClick} 
            selectedPlaceId={selectedPlaceId} 
          />
        </div>

        <div className="list-overlay" style={{paddingTop:'5px'}}>
          <PlaceList 
            places={places} 
            selectedPlaceId={selectedPlaceId} 
            onPlaceClick={handlePlaceClick}
            onDeletePlace={handleDeletePlace}
            onEditPlace={handleEditPlace}
            onVisitedIconClick={handleVisitedIconClick}
            showMustGo={showMustGo}
            setShowMustGo={setShowMustGo}
            showLuxury={showLuxury}
            setShowLuxury={setShowLuxury}
            showHealthy={showHealthy}
            setShowHealthy={setShowHealthy}
            showOthers={showOthers}
            setShowOthers={setShowOthers}
            showVisited={showVisited}
            setShowVisited={setShowVisited}
            mustGoExpanded={mustGoExpanded}
            setMustGoExpanded={setMustGoExpanded}
            luxuryExpanded={luxuryExpanded}
            setLuxuryExpanded={setLuxuryExpanded}
            healthyExpanded={healthyExpanded}
            setHealthyExpanded={setHealthyExpanded}
            othersExpanded={othersExpanded}
            setOthersExpanded={setOthersExpanded}
            visitedExpanded={visitedExpanded}
            setVisitedExpanded={setVisitedExpanded}
          />
        </div>

        <button className="add-place-fab" style={{marginTop:'5px'}} onClick={handleAddPlace}>Add Place</button>

        {(showModal || editingPlace) && (
          <PlaceModal
            isEdit={!!editingPlace}
            place={editingPlace || null}
            onClose={handleCloseModal}
            onSave={handleSave}
          />
        )}

        {showVisitedModal && visitedPlace && (
          <VisitedModal
            place={visitedPlace}
            onClose={handleCloseVisitedModal}
            onSave={handleVisitedSave}
          />
        )}
      </div>
    );
  } else {
    let content;
    let showBackButton = false;

    if (focusedPlaceId && focusedPlace) {
      content = (
        <>
          <div className="map-container-fullscreen">
            <MapComponent 
              places={filteredPlacesForMap} 
              onMarkerClick={handleMarkerClick} 
              selectedPlaceId={focusedPlaceId}
            />
          </div>
          <button className="mobile-back-btn" onClick={handleBack}>
            <IoArrowBackOutline style={{color:'#000', fontSize:'20px'}}/>
          </button>
          {showFocusedCard && (
            <div className="focused-place-card">
              <PlaceList.PlaceCard
                place={focusedPlace}
                selectedPlaceId={focusedPlaceId}
                onPlaceClick={null}
                onDeletePlace={handleDeletePlace}
                onEditPlace={handleEditPlace}
                onVisitedIconClick={handleVisitedIconClick}
                isFocusedView={true}
              />
            </div>
          )}
        </>
      );
    } else {
      if (viewMode === 'list') {
        content = (
          <div className="mobile-list-fullscreen">
            <PlaceList 
              places={places} 
              selectedPlaceId={selectedPlaceId} 
              onPlaceClick={handlePlaceClick}
              onDeletePlace={handleDeletePlace}
              onEditPlace={handleEditPlace}
              onVisitedIconClick={handleVisitedIconClick}
              showMustGo={showMustGo}
              setShowMustGo={setShowMustGo}
              showLuxury={showLuxury}
              setShowLuxury={setShowLuxury}
              showHealthy={showHealthy}
              setShowHealthy={setShowHealthy}
              showOthers={showOthers}
              setShowOthers={setShowOthers}
              showVisited={showVisited}
              setShowVisited={setShowVisited}
              mustGoExpanded={mustGoExpanded}
              setMustGoExpanded={setMustGoExpanded}
              luxuryExpanded={luxuryExpanded}
              setLuxuryExpanded={setLuxuryExpanded}
              healthyExpanded={healthyExpanded}
              setHealthyExpanded={setHealthyExpanded}
              othersExpanded={othersExpanded}
              setOthersExpanded={setOthersExpanded}
              visitedExpanded={visitedExpanded}
              setVisitedExpanded={setVisitedExpanded}
            />
          </div>
        );
      } else {
        showBackButton = true;
        content = (
          <div className="map-container-fullscreen">
            <MapComponent 
              places={filteredPlacesForMap} 
              onMarkerClick={handleMarkerClick} 
              selectedPlaceId={selectedPlaceId}
            />
          </div>
        );
      }
    }

    return (
      <div className="app-container mobile" style={{paddingTop:'5px'}}>
        {content}
        {!focusedPlaceId && (
          <div className="mobile-bottom-toggle">
            <button 
              className={`toggle-btn ${viewMode === 'list' ? 'active' : ''}`} 
              onClick={() => setViewMode('list')}
            >
              List
            </button>
            <button 
              className={`toggle-btn ${viewMode === 'map' ? 'active' : ''}`} 
              onClick={() => setViewMode('map')}
            >
              Map
            </button>
          </div>
        )}
        {showBackButton && !focusedPlaceId && (
          <button className="mobile-back-btn" onClick={() => setViewMode('list')}>
            <IoArrowBackOutline style={{color:'#000', fontSize:'20px'}}/>
          </button>
        )}
        <button className="add-place-fab mobile" onClick={handleAddPlace}>+</button>

        {(showModal || editingPlace) && (
          <PlaceModal
            isEdit={!!editingPlace}
            place={editingPlace || null}
            onClose={handleCloseModal}
            onSave={handleSave}
          />
        )}

        {showVisitedModal && visitedPlace && (
          <VisitedModal
            place={visitedPlace}
            onClose={handleCloseVisitedModal}
            onSave={handleVisitedSave}
          />
        )}
      </div>
    );
  }
}

export default App;
